import React, {
  useContext,
  useState,
  Fragment,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { ThemeContext } from "styled-components";
import CategoryFillIcon from "../icons/CategoryFillIcon";
import { ArrowRightIcon } from "@heroicons/react/solid";

import PrintOutlineIcon from "../icons/PrintOutlineIcon";
import EditIcon from "../icons/EditIcon";
import DeleteIcon from "../icons/DeleteIcon";
import { Dialog, Transition, Menu } from "@headlessui/react";
import ReactJson from "react-json-view";
import classNames from "classnames";
import RightArrowIcon from "../icons/RightArrowIcon";
import WithLoader from "./WithLoader";
import EmptyTransactionIcon from "../icons/EmptyTransactionIcon";
import apiEndpoints from "../apiEndpoints";
import AppContext from "../contexts/AppContext";
import appFetch from "../appFetch";
import DrillModalDialog from "./DrillModalDialog";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import RecentIcon from "../icons/RecentIcon";
import {
  ViewActiveIcon,
  ViewInactiveIcon,
  EditActiveIcon,
  EditInactiveIcon,
  DeleteActiveIcon,
  DeleteInactiveIcon,
} from "../LineIcons";
import ModalLayout1 from "./ModalLayout1";
import SearchControl1 from "./SearchControl1";
import ListPagination from "./ListPagination";
import ConfirmDialog from "./ConfirmDialog";
import { appendQueryToUri } from "../util/generalUtil";
import { isString } from "highcharts";

const MySwal = withReactContent(
  Swal.mixin({
    customClass: {
      confirmButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
      cancelButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
    },
    buttonsStyling: false,
  })
);

const EmptyRecord = () => {
  return (
    <div className="grid place-content-center place-items-center h-80 rounded-sm mb-5">
      <EmptyTransactionIcon />
      <h2 className="font-semibold text-md">No record available</h2>
    </div>
  );
};

const BadgeValue = (value) => {
  let color;
  if (value == "PENDING" || value == "Pending") {
    color = "bg-yellow-500";
  } else if (value == "APPROVED" || value == "Approved") {
    color = "bg-green-700";
  } else if (value == "REJECTED" || value == "Rejected") {
    color = "bg-red-700 ";
  } else if (value == "OPEN" || value == "Open") {
    color = " bg-emerald-300  ";
  } else if (value == "CLOSED" || value == "Closed") {
    color = "bg-blue-600";
  } else if (value == "Active" || value == "ACTIVE") {
    color = "bg-blue-600";
  } else {
    color = "bg-gray-600 ";
  }
  // let style = `capitalize text-xs text-white block ${color} rounded-md p-1 my-1 text-center max-w-min truncate`;
  let style = `rounded-tr-lg rounded-bl-lg w-full justify-end relative -top-6 -right-10 max-w-min my-1 capitalize text-xs ${color} px-2 py-1 origin-center -translate-y-1 text-white truncate`;

  return (
    <span className={style} dangerouslySetInnerHTML={{ __html: value }}></span>
  );
};

const PreviewRecord = (item) => {
  let { activeRecord: record, isOpen, setIsOpen } = item;
  let recordProps = Object.keys(record).filter(
    (str) => !["details", "edit"].includes(str)
  );
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  return (
    <>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
        open={isOpen}
      >
        <div className="min-h-screen h-full px-4 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <span className="inline-block h-full align-middle" aria-hidden="true">
            &#8203;
          </span>
          <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
            <Dialog.Title
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              Detail
            </Dialog.Title>
            <div className="mt-2">
              <ul className="grid gap-1 my-6">
                {recordProps
                  .filter((s) => !["details", "ID"].includes(s))
                  .map((str, idx) => {
                    return (
                      <li
                        className={classNames("flex justify-between")}
                        key={idx}
                      >
                        <div
                          className={classNames("py-2 px-3 w-36", {
                            "bg-gray-200": (idx + 1) % 2 != 0,
                          })}
                        >
                          <span className="text-gray-700 font-semibold text-sm">
                            {str}
                          </span>
                        </div>
                        <div
                          className={classNames("ml-0.5 py-2 px-3 flex-grow", {
                            "bg-gray-200": (idx + 1) % 2 != 0,
                          })}
                        >
                          <span className="text-sm">{record[str]}</span>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>

            <div className="mt-4">
              <button
                type="button"
                className="pointer inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-primary border border-transparent rounded-md"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

function SavedRecordList1({
  options,
  editRecord,
  target_id,
  setLoading,
  updateRecord,
  layoutid,
  edit_action,
}) {
  console.log("layout id ====>", layoutid);
  const theme = useContext(ThemeContext);
  // const [records,setRecords] = useState(options)
  console.log("record list", options);
  const [showPreview, setShowPreview] = useState(false);
  const [activeRecord, setActiveRecord] = useState(null);
  const [dataOptions, setDataOptions] = useState(options);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const { context } = useContext(AppContext);
  const [search, setSearch] = useState(null);
  const reInit = () => setSearch(null);
  const [valuee, setValue] = useState((options ?? [])[0]?.grid);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  let optionsData = (dataOptions ?? [])[0];

  useEffect(() => {
    if (search !== null) return serverSearch();
  }, [search]);

  console.log(target_id);

  useEffect(reInit, [target_id]);

  const handleSearch = (() => {
    let timeout;

    return (e) => {
      let value = e.target.value;
      console.log("the handlesearch value is", value);

      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        setSearch(value);
      }, 300);
    };
  })();

  // let records = (dataOptions ?? [])[0]?.grid?.filter((item) => {
  //   if (search == null) return true;
  //   let searchBody = `${item["Caption 1"]} ${item["Caption 2"]} ${
  //     item["Caption 3"]
  //   } ${item["Caption 4"]} ${item["Caption 5"] ?? ""} ${
  //     item["Caption 6"] ?? ""
  //   }`;
  //   return searchBody?.toLowerCase().search(search.toLowerCase()) !== -1;
  // });

  let searchValue = valuee?.filter((item) => {
    if (search == null) return true;
    let searchBody = `${item["Caption 1"]} ${item["Caption 2"]} ${
      item["Caption 3"]
    } ${item["Caption 4"]} ${item["Caption 5"] ?? ""} ${
      item["Caption 6"] ?? ""
    }`;
    return searchBody?.toLowerCase().search(search.toLowerCase()) !== -1;
  });

  const showRecordPreview = (item) => {
    setIsOpen(true);
    setActiveRecord(item);
  };

  const showDetail = (item) => {
    setLoading(true);
    let postData = {
      layoutId: item.layoutid,
      targetId: item.targetid,
      target: item.target,
      drillId: item.drillid?.toString(),
      targetName: item?.target_name,
      targetType: item?.target_type,
    };

    console.log("the showDetails postDATA", postData);

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        console.log("the showdetails response", res);
        const { layoutid, drillid } = item;
        console.log("modal data", {
          ...res,
          layoutid,
          targetid: target_id,
          drillid,
          targetType: item?.target_type,
        });
        setModalData({
          ...res,
          layoutid,
          targetid: target_id,
          drillid,
          targettype: item?.target_type,
        });
        setIsOpen(true);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  const deleteData = (values) => {
    return new Promise((resolve, reject) => {
      let postData = {
        jsonString: JSON.stringify([values]),
        menuId: context?.menuId,
        buttonAction: "DELETE",
        pageButtonActionId: target_id,
      };

      console.log("data to delete", postData);
      // return;

      setLoading(true);
      appFetch
        .post(apiEndpoints.menuAction, {
          body: JSON.stringify(postData),
        })
        .then((res) => {
          let { ERROR_MESSAGE: errMsg, ERROR_NUMBER: errNo } = res;

          console.log("the response from delete item is", res);

          MySwal.fire({
            text: errMsg,
            icon: errNo === 0 ? "success" : "error",
          });

          if (errNo !== 0) {
            throw new Error(errMsg);
          }

          resolve(res);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
      // return result;
    });
  };

  // const deleteData = async (values) => {
  //   console.log(values);
  //   let postData = {
  //     jsonString: JSON.stringify([values]),
  //     menuId: context?.menuId,
  //     buttonAction: "DELETE",
  //     pageButtonActionId: target_id,
  //   };

  //   console.log("data to delete", postData);

  //   setLoading(true);
  //   appFetch
  //     .post(apiEndpoints.menuAction, {
  //       body: JSON.stringify(postData),
  //     })
  //     .then((res) => {
  //       let { ERROR_MESSAGE: errMsg, ERROR_NUMBER: errNo } = res;

  //       console.log("the response from delete item is", res);

  //       MySwal.fire({
  //         text: errMsg,
  //         icon: errNo === 0 ? "success" : "error",
  //       });

  //       if (errNo !== 0) {
  //         throw new Error(errMsg);
  //       }
  //     })
  //     .catch((err) => console.log(err))
  //     .finally(() => setLoading(false));

  //   return "deleted";
  // };

  const deleteRecord = async (item) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    console.log("item to delete", item);
    let { ID } = item;

    let data = {};
    let ref = data;
    let target = item["root"];
    if (typeof target != "undefined") {
      ref[target] = {};
      ref = ref[target];
    }

    ref["ID"] = ID;

    let result = await deleteData(data);

    // let savedRecords = result?.layout?.children?.find(({type}) => type === "SavedRecordList1") ??
    // result?.layout?.children?.find(({type}) => type === "SavedTransactionList1")

    console.log("the result is saved", result, result.ERROR_MESSAGE);
    // updateRecord(savedRecords)

    if (result.constructor == {}.constructor) {
      let savedRecords = result?.layout?.subcomponents?.find(
        ({ type }) => type === "SavedRecordList1"
      );
      console.log("new saved record", savedRecords);
      setDataOptions(savedRecords?.options);
      setValue(savedRecords?.options[0]?.grid);
      updateRecord(savedRecords);
    }

    // return true;
  };

  console.log("what are the data options", dataOptions);
  console.log("what are the data valuee", valuee);
  console.log("lets see the modal data", modalData);

  // records = records || [];

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  const handleSelectAll = () => {
    if (isCheck.length >= 2 && !isCheckAll) return setIsCheck([]);

    setIsCheckAll(!isCheckAll);
    setIsCheck(
      (serverSearched ? combinedResults : searchValue)?.map((li) => li.ID)
    );

    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    console.log(e.target);
    const { id, checked } = e.target;
    console.log(checked);
    setIsCheck([...isCheck, parseInt(id)]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== parseInt(id)));
    }
  };

  console.log(isCheck);

  const Checkbox = ({ id, type, name, handleClick, isChecked }) => {
    return (
      <input
        id={id}
        name={name}
        type={type}
        onChange={handleClick}
        checked={isChecked}
        className="w-4 h-4 mt-2 accent-gray-600 bg-gray-100 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600"
      />
    );
  };

  const handleDelete = () => {
    let deleteBound = [];
    isCheck.map((id, idx) => {
      valuee?.map((item, idx) => {
        if (item.ID == id) {
          deleteRecord(item);
        }
      });
    });
    // deleteData(isCheck);
    // deleteRecord(deleteBound)
    // console.log(deleteBound);
  };

  // console.log(search);
  const [serverResults, setServerResults] = useState([]);
  const [serverSearched, setServerSearched] = useState(false);

  const serverSearch = useCallback(() => {
    let timerId = null;
    setServerSearched(false);
    const searches = () => {
      let postData = {
        urlMenu: null,
        LayoutId: layoutid,
        targetId: target_id,
        target: "PAGING",
        schemaId: null,
        parentDropValue: null,
        drillId: null,
        targetType: null,
        targetName: null,
        pageSize: optionsData?.records_per_page,
        searchText: search,
      };

      appFetch
        .get(appendQueryToUri(apiEndpoints.menuView, postData))
        .then((res) => {
          console.log("server search", res);
          console.log("Server search", res?.options[0]?.grid);
          setServerResults(res?.options[0]?.grid);
          setServerSearched(true);
        })
        .catch((e) => console.log(e.message));
    };

    const debounceSearch = () => {
      if (timerId) {
        clearTimeout(timerId);
      }

      timerId = setTimeout(searches, 300);
    };

    debounceSearch();
  });

  const combinedResults = [
    ...searchValue,
    ...serverResults?.filter(
      (serverResult) =>
        !searchValue?.some(
          (clientResult) => clientResult.ID === serverResult.ID
        )
    ),
  ];

  console.log(
    "the search and search value",
    searchValue,
    combinedResults,
    search
  );

  return (
    <>
      {/* {isOpen && <PreviewRecord {...{activeRecord, isOpen, setIsOpen}}  />} */}
      {isOpen && (
        <DrillModalDialog
          isOpen={isOpen}
          closeModal={() => setIsOpen(false)}
          item={modalData}
        />
      )}
      <div className="grid gap-2 record-list">
        {(combinedResults?.length > 0 || search) && (
          <SearchControl1
            handleSearch={handleSearch}
            search={search}
            searched={serverSearched}
          />
        )}
        <div className="flex justify-between">
          {!(
            combinedResults?.length == 0 ||
            !combinedResults ||
            searchValue?.length < 1
          ) && (
            <div className="gap-1 flex items-center cursor-pointer">
              <input
                type="checkbox"
                id="selectAll"
                onChange={handleSelectAll}
                isChecked={isCheckAll}
                className="checkbox-style-1 accent-gray-600 bg-gray-100 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600"
                checked={isCheckAll || isCheck.length > 1}
              />
              <label htmlFor="selectAll">
                <span className="text-xs uppercase">
                  {!isCheckAll && isCheck.length < 2
                    ? "Select All"
                    : "Deselect All"}
                </span>
              </label>
            </div>
          )}

          {(isCheckAll || isCheck?.length > 0) && (
            <button
              class="bg-primary hover:bg-primary text-white tracking-normal font-semibold text-xs py-2 px-4 rounded inline-flex items-center"
              onClick={async () =>
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure you want to delete these records?",
                  subtitle: "You can't undo this operation",
                  onConfirm: async () => {
                    await handleDelete();
                  },
                })
              }
            >
              <span class="text-xs">Delete {isCheck.length} selected</span>
            </button>
          )}
        </div>
        {searchValue &&
          (serverSearched ? combinedResults : searchValue)?.map((item, idx) => {
            // const {Posted_By,Date,Name} = item
            console.log("let's check for caption", item);
            console.log(item["Caption 1"]);
            return (
              <div
                className={
                  isCheck.includes(item.ID)
                    ? "bg-slate-100 shadow-1/2 flex border border-md rounded-lg shadow-1/2 mb-4 items-center p-4"
                    : "shadow-1/2 flex border border-md rounded-lg shadow-1/2 mb-4 items-center p-4"
                }
                key={idx}
              >
                <div class="pr-4">
                  <Checkbox
                    key={item.ID}
                    type="checkbox"
                    name={item["Caption 1"]}
                    id={item.ID}
                    handleClick={handleClick}
                    isChecked={isCheck.includes(item.ID)}
                  />
                </div>
                <div class="grid grid-cols-2 pl-16 flex justify-between items-center gap-4 relative w-full">
                  <div
                    className="absolute left-0 border w-12 h-12 rounded-lg grid place-items-center"
                    style={{ backgroundColor: "#F3F3F3" }}
                  >
                    <span className="text-xs font-bold inline-grid place-items-center">
                      {item["Ref"]}
                    </span>
                  </div>
                  <div className="grid">
                    {item["Caption 1"] && (
                      <span
                        className="capitalize text-sm block truncate"
                        dangerouslySetInnerHTML={{ __html: item["Caption 1"] }}
                      ></span>
                    )}
                    {item["Caption 3"] && (
                      <span
                        className="capitalize text-xs text-gray block truncate"
                        dangerouslySetInnerHTML={{ __html: item["Caption 3"] }}
                      ></span>
                    )}
                    {item["Caption 5"] && (
                      <span
                        className="capitalize text-xs block truncate"
                        dangerouslySetInnerHTML={{ __html: item["Caption 5"] }}
                      ></span>
                    )}
                    {item["Caption 7"] && (
                      <span
                        className="capitalize text-xs block truncate"
                        dangerouslySetInnerHTML={{ __html: item["Caption 7"] }}
                      ></span>
                    )}
                    {item["Caption 9"] && (
                      <span
                        className="capitalize text-xs block truncate"
                        style={{ color: "#9a9fad" }}
                        dangerouslySetInnerHTML={{ __html: item["Caption 9"] }}
                      ></span>
                    )}
                    {item["Caption 11"] && (
                      <span
                        className="capitalize text-xs block truncate"
                        style={{ color: "#9a9fad" }}
                        dangerouslySetInnerHTML={{ __html: item["Caption 11"] }}
                      ></span>
                    )}
                  </div>
                  <div className="grid pr-4">
                    {item["Caption 2"] && (
                      <span
                        className="capitalize text-sm block truncate"
                        dangerouslySetInnerHTML={{ __html: item["Caption 2"] }}
                      ></span>
                    )}
                    {item["Caption 4"] && (
                      <span
                        className="capitalize text-xs text-gray block truncate"
                        dangerouslySetInnerHTML={{ __html: item["Caption 4"] }}
                      ></span>
                    )}
                    {item["Caption 6"] && (
                      <span
                        className="capitalize text-xs block truncate"
                        dangerouslySetInnerHTML={{ __html: item["Caption 6"] }}
                      ></span>
                    )}
                    {item["Caption 8"] && (
                      <span
                        className="capitalize text-xs block truncate"
                        dangerouslySetInnerHTML={{ __html: item["Caption 8"] }}
                      ></span>
                    )}
                    {item["Caption 10"] && (
                      <span
                        className="capitalize text-xs block truncate"
                        style={{ color: "#9a9fad" }}
                        dangerouslySetInnerHTML={{ __html: item["Caption 10"] }}
                      ></span>
                    )}
                    {item["Caption 12"] && (
                      <span
                        className="capitalize text-xs block truncate"
                        style={{ color: "#9a9fad" }}
                        dangerouslySetInnerHTML={{ __html: item["Caption 12"] }}
                      ></span>
                    )}
                  </div>
                </div>

                {item["Status"] && BadgeValue(item["Status"])}
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button
                      className={`inline-flex ${
                        item["Status"] && "pt-5"
                      } justify-center w-full px-0.5 py-1 text-sm font-medium text-custom-gray-200 rounded-sm align-bottom`}
                    >
                      <DotsVerticalIcon
                        width={12}
                        height={12}
                        className="w-5 h-5 text-violet-200 hover:text-violet-100"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    {edit_action ? (
                      (edit_action = "all" && (
                        <Menu.Items className="absolute z-10 right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="px-1 py-1 ">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={() => showDetail(item)}
                                  className={`${
                                    active
                                      ? "bg-primary text-white"
                                      : "text-gray-900"
                                  } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                >
                                  {active ? (
                                    <ViewActiveIcon
                                      className="w-5 h-5 mr-2"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <ViewInactiveIcon
                                      className="w-5 h-5 mr-2"
                                      aria-hidden="true"
                                    />
                                  )}
                                  View
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={() => editRecord(item)}
                                  className={`${
                                    active
                                      ? "bg-primary text-white"
                                      : "text-gray-900"
                                  } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                >
                                  {active ? (
                                    <EditActiveIcon
                                      className="w-5 h-5 mr-2"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <EditInactiveIcon
                                      className="w-5 h-5 mr-2"
                                      aria-hidden="true"
                                    />
                                  )}
                                  Edit
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={async () =>
                                    setConfirmDialog({
                                      isOpen: true,
                                      title:
                                        "Are you sure you want to delete this record?",
                                      subtitle: "You can't undo this operation",
                                      onConfirm: async () => {
                                        await deleteRecord(item);
                                      },
                                    })
                                  }
                                  // onClick={() => deleteRecord(item)}
                                  className={`${
                                    active
                                      ? "bg-primary text-white"
                                      : "text-gray-900"
                                  } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                >
                                  {active ? (
                                    <DeleteActiveIcon
                                      className="w-5 h-5 mr-2 text-violet-400"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <DeleteInactiveIcon
                                      className="w-5 h-5 mr-2 text-violet-400"
                                      aria-hidden="true"
                                    />
                                  )}
                                  Delete
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      ))(
                        (edit_action = "edit only" && (
                          <Menu.Items className="absolute z-10 right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="px-1 py-1 ">
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() => showDetail(item)}
                                    className={`${
                                      active
                                        ? "bg-primary text-white"
                                        : "text-gray-900"
                                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                  >
                                    {active ? (
                                      <ViewActiveIcon
                                        className="w-5 h-5 mr-2"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <ViewInactiveIcon
                                        className="w-5 h-5 mr-2"
                                        aria-hidden="true"
                                      />
                                    )}
                                    View
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() => editRecord(item)}
                                    className={`${
                                      active
                                        ? "bg-primary text-white"
                                        : "text-gray-900"
                                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                  >
                                    {active ? (
                                      <EditActiveIcon
                                        className="w-5 h-5 mr-2"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <EditInactiveIcon
                                        className="w-5 h-5 mr-2"
                                        aria-hidden="true"
                                      />
                                    )}
                                    Edit
                                  </button>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        ))
                      )(
                        (edit_action = "delete only" && (
                          <Menu.Items className="absolute z-10 right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="px-1 py-1 ">
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() => showDetail(item)}
                                    className={`${
                                      active
                                        ? "bg-primary text-white"
                                        : "text-gray-900"
                                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                  >
                                    {active ? (
                                      <ViewActiveIcon
                                        className="w-5 h-5 mr-2"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <ViewInactiveIcon
                                        className="w-5 h-5 mr-2"
                                        aria-hidden="true"
                                      />
                                    )}
                                    View
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={async () =>
                                      setConfirmDialog({
                                        isOpen: true,
                                        title:
                                          "Are you sure you want to delete this record?",
                                        subtitle:
                                          "You can't undo this operation",
                                        onConfirm: async () => {
                                          await deleteRecord(item);
                                        },
                                      })
                                    }
                                    // onClick={() => deleteRecord(item)}
                                    className={`${
                                      active
                                        ? "bg-primary text-white"
                                        : "text-gray-900"
                                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                  >
                                    {active ? (
                                      <DeleteActiveIcon
                                        className="w-5 h-5 mr-2 text-violet-400"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <DeleteInactiveIcon
                                        className="w-5 h-5 mr-2 text-violet-400"
                                        aria-hidden="true"
                                      />
                                    )}
                                    Delete
                                  </button>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        ))
                      )
                    ) : (
                      <Menu.Items className="absolute z-10 right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() => showDetail(item)}
                                className={`${
                                  active
                                    ? "bg-primary text-white"
                                    : "text-gray-900"
                                } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                              >
                                {active ? (
                                  <ViewActiveIcon
                                    className="w-5 h-5 mr-2"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <ViewInactiveIcon
                                    className="w-5 h-5 mr-2"
                                    aria-hidden="true"
                                  />
                                )}
                                View
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() => editRecord(item)}
                                className={`${
                                  active
                                    ? "bg-primary text-white"
                                    : "text-gray-900"
                                } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                              >
                                {active ? (
                                  <EditActiveIcon
                                    className="w-5 h-5 mr-2"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <EditInactiveIcon
                                    className="w-5 h-5 mr-2"
                                    aria-hidden="true"
                                  />
                                )}
                                Edit
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={async () =>
                                  setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      "Are you sure you want to delete this record?",
                                    subtitle: "You can't undo this operation",
                                    onConfirm: async () => {
                                      await deleteRecord(item);
                                    },
                                  })
                                }
                                // onClick={() => deleteRecord(item)}
                                className={`${
                                  active
                                    ? "bg-primary text-white"
                                    : "text-gray-900"
                                } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                              >
                                {active ? (
                                  <DeleteActiveIcon
                                    className="w-5 h-5 mr-2 text-violet-400"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <DeleteInactiveIcon
                                    className="w-5 h-5 mr-2 text-violet-400"
                                    aria-hidden="true"
                                  />
                                )}
                                Delete
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    )}
                  </Transition>
                </Menu>
              </div>
            );
          })}
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <ListPagination
          search={search}
          records={searchValue}
          itemsPerPage={optionsData?.records_per_page}
          pageRangeDisplayed={3}
          item={showRecordPreview}
          changedRecords={(record) => setValue(record)}
          targetId={target_id}
          pages={optionsData?.pages}
          setLoading={setLoading}
          layoutId={layoutid}
        ></ListPagination>
      </div>
      {((serverSearched && combinedResults?.length == 0) ||
        !combinedResults) && (
        <div className="grid place-content-center place-items-center h-80 rounded-sm mb-5">
          <EmptyTransactionIcon />
          <h2 className="font-semibold text-sm mt-2">
            No record found {search && `that matches "${search}"`}{" "}
          </h2>
        </div>
      )}

      {!serverSearched && search && (
        <div className="grid place-content-center place-items-center h-80 rounded-sm mb-5">
          <h2 className="font-semibold text-md mt-2">
            Fetching more results for {search && `"${search}"`}{" "}
          </h2>
        </div>
      )}

      {/* <ReactPaginate 
              breaklabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed= {3}
              pageCount = {pageCount}
              previousLabel = "< previous"
              renderOnZeroPageCount = {null}
          /> */}
    </>
  );
}

export default WithLoader(SavedRecordList1);

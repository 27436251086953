import * as React from "react";

const RightArrowIcon = (props) => (
  <svg
    width={12}
    height={9}
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.8403 3.97134L8.02208 0.153117C7.9192 0.0537573 7.78142 -0.00122217 7.6384 2.06197e-05C7.49538 0.00126341 7.35858 0.058629 7.25744 0.159762C7.15631 0.260894 7.09894 0.397702 7.0977 0.54072C7.09646 0.683738 7.15144 0.821522 7.2508 0.924397L10.1379 3.81152H0.54546C0.400795 3.81152 0.262055 3.86898 0.159761 3.97128C0.0574679 4.07357 0 4.21231 0 4.35698C0 4.50164 0.0574679 4.64038 0.159761 4.74267C0.262055 4.84497 0.400795 4.90244 0.54546 4.90244H10.1379L7.2508 7.78955C7.1987 7.83987 7.15715 7.90006 7.12856 7.96661C7.09997 8.03316 7.08493 8.10473 7.0843 8.17716C7.08367 8.24958 7.09747 8.32141 7.12489 8.38844C7.15232 8.45548 7.19282 8.51638 7.24404 8.5676C7.29525 8.61881 7.35615 8.65931 7.42319 8.68674C7.49022 8.71416 7.56205 8.72797 7.63447 8.72734C7.7069 8.72671 7.77848 8.71166 7.84502 8.68307C7.91157 8.65449 7.97176 8.61293 8.02208 8.56083L11.8403 4.74262C11.9426 4.64033 12 4.50161 12 4.35698C12 4.21234 11.9426 4.07362 11.8403 3.97134Z"
      fill="#BBBBBB"
    />
  </svg>
);

export default RightArrowIcon;

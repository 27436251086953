import * as React from "react";

const CancelIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="9"
    //   fill="red"
      stroke="red"
      stroke-width="2"
    />
    <path
      d="M18 18L6 6"
      fill="red"
      stroke="red"
      stroke-width="2"
    />
  </svg>
);

export default CancelIcon;

import React from "react";

import UndeployedTenderIcon from "./UndeployedTenderIcon";
import ActiveTenderIcon from "./ActiveTenderIcon";
import ClosingTenderIcon from "./ClosingTenderIcon";
import ClosedTenderIcon from "./ClosedTenderIcon";

export default {
  UndeployedTenderIcon,
  ActiveTenderIcon,
  ClosingTenderIcon,
  ClosedTenderIcon
};

import * as React from "react";

const CategoryIcon = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 1.5H3C2.17157 1.5 1.5 2.17157 1.5 3V5C1.5 5.82843 2.17157 6.5 3 6.5H5C5.82843 6.5 6.5 5.82843 6.5 5V3C6.5 2.17157 5.82843 1.5 5 1.5ZM3 0C1.34315 0 0 1.34315 0 3V5C0 6.65685 1.34315 8 3 8H5C6.65685 8 8 6.65685 8 5V3C8 1.34315 6.65685 0 5 0H3Z"
      fill="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 11.5H3C2.17157 11.5 1.5 12.1716 1.5 13V15C1.5 15.8284 2.17157 16.5 3 16.5H5C5.82843 16.5 6.5 15.8284 6.5 15V13C6.5 12.1716 5.82843 11.5 5 11.5ZM3 10C1.34315 10 0 11.3431 0 13V15C0 16.6569 1.34315 18 3 18H5C6.65685 18 8 16.6569 8 15V13C8 11.3431 6.65685 10 5 10H3Z"
      fill="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 1.5H13C12.1716 1.5 11.5 2.17157 11.5 3V5C11.5 5.82843 12.1716 6.5 13 6.5H15C15.8284 6.5 16.5 5.82843 16.5 5V3C16.5 2.17157 15.8284 1.5 15 1.5ZM13 0C11.3431 0 10 1.34315 10 3V5C10 6.65685 11.3431 8 13 8H15C16.6569 8 18 6.65685 18 5V3C18 1.34315 16.6569 0 15 0H13Z"
      fill="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 11.5H13C12.1716 11.5 11.5 12.1716 11.5 13V15C11.5 15.8284 12.1716 16.5 13 16.5H15C15.8284 16.5 16.5 15.8284 16.5 15V13C16.5 12.1716 15.8284 11.5 15 11.5ZM13 10C11.3431 10 10 11.3431 10 13V15C10 16.6569 11.3431 18 13 18H15C16.6569 18 18 16.6569 18 15V13C18 11.3431 16.6569 10 15 10H13Z"
      fill="inherit"
    />
  </svg>
);

export default CategoryIcon;

import * as React from "react";

const ClosedTenderIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    fill="none"
    viewBox="0 0 26 26"
  >
    <path
      fill="#D9241C"
      d="M5.2 0h15.6A5.2 5.2 0 0126 5.2v15.6a5.2 5.2 0 01-5.2 5.2H5.2A5.2 5.2 0 010 20.8V5.2A5.2 5.2 0 015.2 0zm0 2.6a2.6 2.6 0 00-2.6 2.6v15.6a2.6 2.6 0 002.6 2.6h15.6a2.6 2.6 0 002.6-2.6V5.2a2.6 2.6 0 00-2.6-2.6H5.2zM14.838 13l3.678 3.676a1.3 1.3 0 01-1.84 1.84L13 14.838l-3.676 3.678a1.3 1.3 0 01-1.84-1.84L11.162 13 7.484 9.324a1.3 1.3 0 011.84-1.84L13 11.162l3.676-3.678a1.3 1.3 0 111.84 1.84L14.838 13z"
    ></path>
  </svg>
);

export default ClosedTenderIcon;

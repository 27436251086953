import * as React from "react";

const MemoIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    {...props}
    fill="none"
  >
    <path
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M5 13.03h4m10 0v-10a2 2 0 00-2-2H3a2 2 0 00-2 2v14a2 2 0 002 2h10l6-6zm0 0v.173a2 2 0 01-.586 1.414l-3.828 3.828a2 2 0 01-1.414.586H13l6-6zm0 0h-4a2 2 0 00-2 2v4l6-6zm-14-8h10H5zm0 4h10H5z"
    ></path>
  </svg>
);

export default MemoIcon;

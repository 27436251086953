import React, { createContext, useContext, useCallback, useReducer, useMemo } from 'react';

// Action types
const SET_FILTER = 'SET_FILTER';
const RESET_FILTERS = 'RESET_FILTERS';

// Reducer function
const filterReducer = (state, action) => {
  switch (action.type) {
    case SET_FILTER:
      return { ...state, [action.payload.key]: action.payload.value };
    case RESET_FILTERS:
      return {};
    default:
      return state;
  }
};

// Create the context
const FilterContext = createContext();

// Create a provider component
export function FilterProvider({ children, initialFilters = {} }) {
  const [filters, dispatch] = useReducer(filterReducer, initialFilters);

  const setFilter = useCallback((key, value) => {
    dispatch({ type: SET_FILTER, payload: { key, value } });
  }, []);

  const resetFilters = useCallback(() => {
    dispatch({ type: RESET_FILTERS });
  }, []);

  const value = useMemo(() => ({
    filters,
    setFilter,
    resetFilters
  }), [filters, setFilter, resetFilters]);

  return (
    <FilterContext.Provider value={value}>
      {children}
    </FilterContext.Provider>
  );
}

// Custom hook to use the filter context
export function useFilters() {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilters must be used within a FilterProvider');
  }
  return context;
}

// Custom hook for individual filter values
export function useFilter(key) {
  const { filters, setFilter } = useFilters();
  const value = filters[key];
  const setValue = useCallback((newValue) => setFilter(key, newValue), [key, setFilter]);
  return [value, setValue];
}
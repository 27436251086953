import * as React from "react";

const PrintIcon = (props) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 10.5H11.75C12.0812 10.499 12.3986 10.367 12.6328 10.1328C12.867 9.89858 12.999 9.58122 13 9.25V4.25C12.999 3.91878 12.867 3.60142 12.6328 3.36721C12.3986 3.133 12.0812 3.00099 11.75 3H2.25C1.91878 3.00099 1.60142 3.133 1.36721 3.36721C1.133 3.60142 1.00099 3.91878 1 4.25V9.25C1.00099 9.58122 1.133 9.89858 1.36721 10.1328C1.60142 10.367 1.91878 10.499 2.25 10.5H3"
      stroke="#B24190"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <path
      d="M10.2398 6.5H3.75976C3.34002 6.5 2.99976 6.84026 2.99976 7.26V12.24C2.99976 12.6597 3.34002 13 3.75976 13H10.2398C10.6595 13 10.9998 12.6597 10.9998 12.24V7.26C10.9998 6.84026 10.6595 6.5 10.2398 6.5Z"
      stroke="#B24190"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <path
      d="M10.9998 3V2.25C10.9988 1.91878 10.8668 1.60142 10.6325 1.36721C10.3983 1.133 10.081 1.00099 9.74976 1H4.24976C3.91854 1.00099 3.60117 1.133 3.36697 1.36721C3.13276 1.60142 3.00075 1.91878 2.99976 2.25V3"
      stroke="#B24190"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <path
      d="M11.2498 5.5C11.664 5.5 11.9998 5.16421 11.9998 4.75C11.9998 4.33579 11.664 4 11.2498 4C10.8355 4 10.4998 4.33579 10.4998 4.75C10.4998 5.16421 10.8355 5.5 11.2498 5.5Z"
      fill="#B24190"
    />
  </svg>
);

export default PrintIcon;

import * as React from "react";

const HomeIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 20C1.34315 20 0 18.6569 0 17V9.33613C0 8.48567 0.360964 7.67518 0.993106 7.10625L7.9931 0.806251C9.13402 -0.220572 10.866 -0.220574 12.0069 0.80625L19.0069 7.10625C19.639 7.67518 20 8.48567 20 9.33613V17C20 18.6569 18.6569 20 17 20H3ZM18.5 9.33613V17C18.5 17.8285 17.8284 18.5 17 18.5H14V13C14 11.3432 12.6569 10 11 10H9C7.34315 10 6 11.3432 6 13V18.5H3C2.17157 18.5 1.5 17.8285 1.5 17V9.33613C1.5 8.9109 1.68048 8.50565 1.99655 8.22119L8.99655 1.92119C9.56701 1.40778 10.433 1.40778 11.0034 1.92119L18.0034 8.22119C18.3195 8.50565 18.5 8.9109 18.5 9.33613ZM7.5 18.5V13C7.5 12.1716 8.17157 11.5 9 11.5H11C11.8284 11.5 12.5 12.1716 12.5 13V18.5H7.5Z"
      fill="inherit"
    />
  </svg>
);

export default HomeIcon;

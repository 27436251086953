import * as React from "react";

const CircleIcon = (props) => (
  <svg
    width={15}
    height={15}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={7.5} cy={7.5} r={7} stroke="inherit" />
  </svg>
);

export default CircleIcon;

import * as React from "react";

const OpenIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="22"
    fill="none"
    viewBox="0 0 18 22"
    {...props}
  >
    <path
      fill="inherit"
      d="M7.773 7.703h1.574v7.08H7.773v-7.08zM6.2.623h4.72v1.573H6.2V.622z"
    ></path>
    <path
      fill="inherit"
      d="M18 6.13l-1.117-1.11-1.77 1.77a8.606 8.606 0 10.928 1.298L18 6.13zM8.56 19.502a7.08 7.08 0 110-14.16 7.08 7.08 0 010 14.16z"
    ></path>
  </svg>
);

export default OpenIcon;

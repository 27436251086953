import * as React from "react";

const ActiveTenderIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="30"
    fill="none"
    viewBox="0 0 32 30"
  >
    <path
      fill="#D9241C"
      fillRule="evenodd"
      d="M12.174 6.006c-1.477.274-2.89.807-4.155 1.576C6.097 8.75 4.6 10.41 3.715 12.351a9.732 9.732 0 00-.665 6.138c.451 2.061 1.564 3.955 3.198 5.44 1.635 1.487 3.717 2.498 5.984 2.908 2.267.41 4.616.2 6.752-.604 2.135-.804 3.96-2.166 5.244-3.913a10.126 10.126 0 001.733-3.778h-4.826c-.194.5-.456.977-.783 1.422-.772 1.05-1.87 1.87-3.152 2.353a7.678 7.678 0 01-4.059.363 7.266 7.266 0 01-3.596-1.748c-.982-.893-1.651-2.03-1.922-3.27a5.85 5.85 0 01.4-3.69c.53-1.166 1.431-2.164 2.586-2.865a7.324 7.324 0 011.565-.713V6.006zm.783-2.26c.855-.088 1.554.555 1.554 1.337v5.656c0 .782-.716 1.394-1.53 1.65a4.894 4.894 0 00-1.073.485 4.376 4.376 0 00-1.726 1.912 3.903 3.903 0 00-.267 2.462c.18.826.627 1.586 1.283 2.182a4.85 4.85 0 002.4 1.166c.908.164 1.85.08 2.707-.243a4.62 4.62 0 002.103-1.57c.225-.304.403-.633.534-.976.28-.74.954-1.39 1.814-1.39h6.22c.861 0 1.568.636 1.472 1.413a12.05 12.05 0 01-2.276 5.67c-1.541 2.097-3.732 3.731-6.294 4.696a15.33 15.33 0 01-8.102.726c-2.72-.492-5.22-1.707-7.18-3.49-1.962-1.783-3.297-4.055-3.838-6.528a11.679 11.679 0 01.798-7.367c1.061-2.33 2.859-4.32 5.165-5.722a14.925 14.925 0 016.236-2.07z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#D9241C"
      fillRule="evenodd"
      d="M29.078 11.458a9.927 9.927 0 00-.654-1.94 10.617 10.617 0 00-2.533-3.448A11.825 11.825 0 0022.1 3.767c-.69-.26-1.406-.459-2.135-.594v4.388a7.107 7.107 0 012.63 1.507 6.383 6.383 0 011.657 2.39h4.826zm2.487.711c.095.778-.611 1.414-1.472 1.414h-6.22c-.86 0-1.534-.65-1.815-1.39a4.259 4.259 0 00-1.116-1.622 4.742 4.742 0 00-1.785-1.016c-.813-.255-1.53-.867-1.53-1.65V2.25c0-.782.7-1.425 1.556-1.338 1.308.133 2.59.432 3.811.892a14.19 14.19 0 014.55 2.764 12.743 12.743 0 013.04 4.136c.505 1.11.835 2.276.98 3.465z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default ActiveTenderIcon;

import * as React from "react";

const ApprovalIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="19"
    viewBox="0 0 20 19"
    {...props}
    fill="none"
  >
    <path
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.7"
      d="M11.8 15.4c3.394 0 5.091 0 6.145-1.055C19 13.291 19 11.594 19 8.2c0-3.394 0-5.091-1.055-6.145C16.891 1 15.194 1 11.8 1H8.2C4.806 1 3.109 1 2.055 2.055 1 3.109 1 4.806 1 8.2c0 3.394 0 5.091 1.055 6.145.587.589 1.375.849 2.545.963"
    ></path>
    <path
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.7"
      d="M7.3 8.2L9.325 10 12.7 6.4"
    ></path>
    <path
      stroke="inherit"
      strokeLinecap="round"
      strokeWidth="1.7"
      d="M11.8 15.4c-1.112 0-2.338.45-3.457 1.03-1.798.934-2.697 1.4-3.14 1.103-.442-.297-.359-1.22-.19-3.064l.037-.419"
    ></path>
  </svg>
);

export default ApprovalIcon;

import * as React from "react";

const PaymentIcon = (props) => (
  <svg
    width={20}
    height={16}
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 10.25C3.58579 10.25 3.25 10.5858 3.25 11C3.25 11.4142 3.58579 11.75 4 11.75H10C10.4142 11.75 10.75 11.4142 10.75 11C10.75 10.5858 10.4142 10.25 10 10.25H4Z"
      fill="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 0C1.34315 0 0 1.34315 0 3V13C0 14.6569 1.34315 16 3 16H17C18.6569 16 20 14.6569 20 13V3C20 1.34315 18.6569 0 17 0H3ZM17 1.5H3C2.17157 1.5 1.5 2.17157 1.5 3V4.25H18.5V3C18.5 2.17157 17.8284 1.5 17 1.5ZM1.5 13V5.75H18.5V13C18.5 13.8284 17.8284 14.5 17 14.5H3C2.17157 14.5 1.5 13.8284 1.5 13Z"
      fill="inherit"
    />
  </svg>
);

export default PaymentIcon;

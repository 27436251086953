import * as React from "react";

const FilterIcon = (props) => (
  <svg
    width={23}
    height={17}
    viewBox="0 0 23 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.7573 1.53846H9.91258C9.91258 0.69 9.15368 0 8.22049 0H6.52839C5.5952 0 4.83629 0.69 4.83629 1.53846H1.4521C0.985078 1.53846 0.606049 1.88231 0.606049 2.30769C0.606049 2.73308 0.985078 3.07692 1.4521 3.07692H4.83629C4.83629 3.92538 5.5952 4.61538 6.52839 4.61538H8.22049C9.15368 4.61538 9.91258 3.92538 9.91258 3.07692H21.7573C22.2243 3.07692 22.6033 2.73308 22.6033 2.30769C22.6033 1.88231 22.2243 1.53846 21.7573 1.53846ZM6.52839 3.07692V1.53846H8.22049L8.22133 2.30462C8.22133 2.30615 8.22049 2.30692 8.22049 2.30769C8.22049 2.30846 8.22133 2.30923 8.22133 2.31077V3.07692H6.52839Z"
      fill="inherit"
    />
    <path
      d="M21.7573 7.69227H16.681C16.681 6.84381 15.9221 6.15381 14.9889 6.15381H13.2968C12.3636 6.15381 11.6047 6.84381 11.6047 7.69227H1.4521C0.985078 7.69227 0.606049 8.03612 0.606049 8.4615C0.606049 8.88689 0.985078 9.23073 1.4521 9.23073H11.6047C11.6047 10.0792 12.3636 10.7692 13.2968 10.7692H14.9889C15.9221 10.7692 16.681 10.0792 16.681 9.23073H21.7573C22.2243 9.23073 22.6033 8.88689 22.6033 8.4615C22.6033 8.03612 22.2243 7.69227 21.7573 7.69227ZM13.2968 9.23073V7.69227H14.9889L14.9897 8.45842C14.9897 8.45996 14.9889 8.46073 14.9889 8.4615C14.9889 8.46227 14.9897 8.46304 14.9897 8.46458V9.23073H13.2968Z"
      fill="inherit"
    />
    <path
      d="M21.7573 13.8462H9.91258C9.91258 12.9977 9.15368 12.3077 8.22049 12.3077H6.52839C5.5952 12.3077 4.83629 12.9977 4.83629 13.8462H1.4521C0.985078 13.8462 0.606049 14.19 0.606049 14.6154C0.606049 15.0408 0.985078 15.3847 1.4521 15.3847H4.83629C4.83629 16.2331 5.5952 16.9231 6.52839 16.9231H8.22049C9.15368 16.9231 9.91258 16.2331 9.91258 15.3847H21.7573C22.2243 15.3847 22.6033 15.0408 22.6033 14.6154C22.6033 14.19 22.2243 13.8462 21.7573 13.8462ZM6.52839 15.3847V13.8462H8.22049L8.22133 14.6124C8.22133 14.6139 8.22049 14.6147 8.22049 14.6154C8.22049 14.6162 8.22133 14.617 8.22133 14.6185V15.3847H6.52839Z"
      fill="inherit"
    />
  </svg>
);

export default FilterIcon;

import * as React from "react";

const CartBagIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={512}
    height={512}
    x={0}
    y={0}
    viewBox="0 0 32 32"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    {...props}
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2">
        <path
          d="m16 1a6 6 0 0 0 -6 6v1h-.83a3.27 3.27 0 0 0 -3.27 3.12l-.79 16.45a3.28 3.28 0 0 0 3.27 3.43h15.24a3.28 3.28 0 0 0 3.27-3.43l-.79-16.45a3.27 3.27 0 0 0 -3.27-3.12h-.83v-1a6 6 0 0 0 -6-6zm-4 6a4 4 0 0 1 8 0v1h-8zm12.1 4.21.79 16.46a1.31 1.31 0 0 1 -.35.94 1.29 1.29 0 0 1 -.92.39h-15.24a1.29 1.29 0 0 1 -.92-.39 1.31 1.31 0 0 1 -.35-.94l.79-16.46a1.27 1.27 0 0 1 1.27-1.21h13.66a1.27 1.27 0 0 1 1.27 1.21z"
          fill="inherit"
        />
        <circle cx={11.36} cy={12.19} r={1} fill="inherit" />
        <circle cx={20.64} cy={12.19} r={1} fill="inherit" />
      </g>
    </g>
  </svg>
);

export default CartBagIcon;

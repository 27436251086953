import * as React from "react";

const UserIcon = (props) => (
  <svg
    width={18}
    height={20}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 8.5C10.933 8.5 12.5 6.933 12.5 5C12.5 3.067 10.933 1.5 9 1.5C7.067 1.5 5.5 3.067 5.5 5C5.5 6.933 7.067 8.5 9 8.5ZM9 10C11.7614 10 14 7.76142 14 5C14 2.23858 11.7614 0 9 0C6.23858 0 4 2.23858 4 5C4 7.76142 6.23858 10 9 10Z"
      fill="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 13.75C3.65279 13.75 1.75 15.6528 1.75 18V19C1.75 19.4142 1.41421 19.75 1 19.75C0.585786 19.75 0.25 19.4142 0.25 19V18C0.25 14.8244 2.82436 12.25 6 12.25H12C15.1756 12.25 17.75 14.8244 17.75 18V19C17.75 19.4142 17.4142 19.75 17 19.75C16.5858 19.75 16.25 19.4142 16.25 19V18C16.25 15.6528 14.3472 13.75 12 13.75H6Z"
      fill="inherit"
    />
  </svg>
);

export default UserIcon;

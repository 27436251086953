import * as React from "react";

const PlusRound = (props) => (
  <svg
    width={21}
    height={21}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.82143 7.10714C9.82143 6.92717 9.89292 6.75458 10.0202 6.62732C10.1474 6.50006 10.32 6.42857 10.5 6.42857C10.68 6.42857 10.8526 6.50006 10.9798 6.62732C11.1071 6.75458 11.1786 6.92717 11.1786 7.10714V9.82143H13.8929C14.0728 9.82143 14.2454 9.89292 14.3727 10.0202C14.4999 10.1474 14.5714 10.32 14.5714 10.5C14.5714 10.68 14.4999 10.8526 14.3727 10.9798C14.2454 11.1071 14.0728 11.1786 13.8929 11.1786H11.1786V13.8929C11.1786 14.0728 11.1071 14.2454 10.9798 14.3727C10.8526 14.4999 10.68 14.5714 10.5 14.5714C10.32 14.5714 10.1474 14.4999 10.0202 14.3727C9.89292 14.2454 9.82143 14.0728 9.82143 13.8929V11.1786H7.10714C6.92717 11.1786 6.75458 11.1071 6.62732 10.9798C6.50006 10.8526 6.42857 10.68 6.42857 10.5C6.42857 10.32 6.50006 10.1474 6.62732 10.0202C6.75458 9.89292 6.92717 9.82143 7.10714 9.82143H9.82143V7.10714ZM20 10.5C20 15.7467 15.7467 20 10.5 20C5.25329 20 1 15.7467 1 10.5C1 5.25329 5.25329 1 10.5 1C15.7467 1 20 5.25329 20 10.5ZM18.6429 10.5C18.6429 6.00311 14.9969 2.35714 10.5 2.35714C6.00311 2.35714 2.35714 6.00311 2.35714 10.5C2.35714 14.9969 6.00311 18.6429 10.5 18.6429C14.9969 18.6429 18.6429 14.9969 18.6429 10.5Z"
      fill="inherit"
      stroke="inherit"
      strokeWidth={0.8}
    />
  </svg>
);

export default PlusRound;

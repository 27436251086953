import * as React from "react";

const DownloadIcon = (props) => (
  <svg
    width={22}
    height={17}
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.8348 0.810045C11.8348 0.36267 11.4412 0 10.9557 0C10.4701 0 10.0765 0.36267 10.0765 0.810045V10.1956L6.30231 6.71807C5.95898 6.40172 5.40233 6.40172 5.059 6.71806C4.71567 7.03441 4.71567 7.5473 5.059 7.86364L9.5051 11.9602C10.3062 12.6984 11.6051 12.6984 12.4062 11.9602L16.85 7.86575C17.1933 7.54941 17.1933 7.03652 16.85 6.72017C16.5066 6.40383 15.95 6.40383 15.6067 6.72017L11.8348 10.1955V0.810045Z"
      fill="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.57569 10.801C2.06123 10.801 2.45484 11.1637 2.45484 11.6111V13.7712C2.45484 14.5168 3.11086 15.1213 3.9201 15.1213H17.9866C18.7958 15.1213 19.4518 14.5168 19.4518 13.7712V11.6111C19.4518 11.1637 19.8454 10.801 20.331 10.801C20.8165 10.801 21.2101 11.1637 21.2101 11.6111V13.7712C21.2101 15.4116 19.7669 16.7414 17.9866 16.7414H3.9201C2.13977 16.7414 0.696533 15.4116 0.696533 13.7712V11.6111C0.696533 11.1637 1.09014 10.801 1.57569 10.801Z"
      fill="inherit"
    />
  </svg>
);

export default DownloadIcon;

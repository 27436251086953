import * as React from "react";

const UsersIcon = (props) => (
  <svg
    width={32}
    height={26}
    viewBox="0 0 32 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.3897 16.933C25.5135 17.1095 25.6868 17.2451 25.885 17.3297C27.6073 18.0647 28.8 19.6557 28.8 21.4999V24.3333C28.8 24.9201 29.3232 25.3958 29.9686 25.3958C30.614 25.3958 31.1372 24.9201 31.1372 24.3333V21.4999C31.1372 17.7835 27.8235 14.7708 23.736 14.7708V14.7708C23.5753 14.7708 23.5068 14.982 23.6328 15.0816C24.3114 15.6177 24.9041 16.2413 25.3897 16.933Z"
      fill="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.15461 16.8959C5.35787 16.8959 3.09066 18.9572 3.09066 21.5V24.3334C3.09066 24.9202 2.56746 25.3959 1.92205 25.3959C1.27665 25.3959 0.753448 24.9202 0.753448 24.3334V21.5C0.753448 17.7836 4.06706 14.7709 8.15461 14.7709H17.5034C21.591 14.7709 24.9046 17.7836 24.9046 21.5V24.3334C24.9046 24.9202 24.3814 25.3959 23.736 25.3959C23.0906 25.3959 22.5674 24.9202 22.5674 24.3334V21.5C22.5674 18.9572 20.3002 16.8959 17.5034 16.8959H8.15461Z"
      fill="inherit"
    />
    <path
      d="M20.429 9.5204C20.5595 9.29338 20.759 9.11455 20.9914 8.99383C22.1655 8.38379 22.9569 7.23425 22.9569 5.91667C22.9569 4.59908 22.1655 3.44954 20.9914 2.83951C20.759 2.71878 20.5595 2.53995 20.429 2.31293C20.0424 1.64033 19.551 1.02543 18.9744 0.486162C18.8846 0.402115 18.9385 0.25 19.0615 0.25V0.25C22.5037 0.25 25.2941 2.78705 25.2941 5.91667C25.2941 9.04628 22.5037 11.5833 19.0615 11.5833V11.5833C18.9385 11.5833 18.8846 11.4312 18.9744 11.3472C19.551 10.8079 20.0424 10.193 20.429 9.5204Z"
      fill="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.829 9.45833C14.9804 9.45833 16.7244 7.87268 16.7244 5.91667C16.7244 3.96066 14.9804 2.375 12.829 2.375C10.6777 2.375 8.93368 3.96066 8.93368 5.91667C8.93368 7.87268 10.6777 9.45833 12.829 9.45833ZM12.829 11.5833C16.2712 11.5833 19.0616 9.04628 19.0616 5.91667C19.0616 2.78705 16.2712 0.25 12.829 0.25C9.38688 0.25 6.59647 2.78705 6.59647 5.91667C6.59647 9.04628 9.38688 11.5833 12.829 11.5833Z"
      fill="inherit"
    />
  </svg>
);

export default UsersIcon;

import * as React from "react";

const ClosingTenderIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    fill="none"
    viewBox="0 0 28 28"
  >
    <path
      fill="#D9241C"
      fillRule="evenodd"
      stroke="#D9241C"
      strokeWidth="0.5"
      d="M25.023 14c0-5.542-4.37-11.045-11.171-11.045-5.493 0-8.208 4.02-9.366 6.158H7.92c.262 0 .514.103.7.286a.972.972 0 010 1.382.994.994 0 01-.7.287H1.99a.994.994 0 01-.7-.287.972.972 0 01-.289-.69V4.225c0-.26.104-.508.29-.692a.994.994 0 011.398 0c.185.184.29.432.29.692V7.77C4.37 5.342 7.591 1 13.851 1 21.885 1 27 7.52 27 14s-5.115 13-13.148 13c-3.844 0-7.043-1.505-9.323-3.796a13.227 13.227 0 01-2.821-4.237.968.968 0 01.217-1.064.99.99 0 01.7-.284.998.998 0 01.698.288.977.977 0 01.214.317 11.238 11.238 0 002.404 3.61c1.922 1.935 4.619 3.211 7.91 3.211 6.802 0 11.172-5.503 11.172-11.045zm-12.16 4.887V9.113h1.977v9.774h-1.977z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default ClosingTenderIcon;

import React from 'react';
import Moment from 'react-moment';
import DownloadIcon from '../icons/DownloadIcon';
import formatter from '../util/formatter';
import uuid from 'react-uuid'
import EmptyTransactionIcon from "../icons/EmptyTransactionIcon"
import icons from "../icons"
import { useTheme } from 'styled-components';
// import EmptyRecord from './EmptyRecord';

const EmptyRecord  = () => {
    return ( <div className='grid place-content-center place-items-center h-60 bg-gray-100 rounded-sm mb-5'>
        <EmptyTransactionIcon  />
        <h2 className='font-semibold text-md'>No transactions available</h2>
        <p className='text-sm text-gray-700'>When you make a transaction, the details will show here</p>
    </div>)
}

function RecentTransactionList1({children,name,options}) {
    const theme = useTheme()
    return (
      <div>
        <header className="flex justify-between items-center mb-3">
          {/* <h2 className="font-bold uppercase text-xl text-recent-gray">{name}</h2> */}
          <h2 className="font-bold uppercase">{name}</h2>
          {/* <div
            className="rounded-lg p-2"
            style={{ backgroundColor: "#F1F2FB" }}
          >
            <DownloadIcon fill="#aaaaaa" />
          </div> */}
        </header>
        <ul className="grid lg:grid-cols-2 gap-x-4 gap-y-5">
          {options &&
            options.map(
              (
                {
                  recent_date: date,
                  recent_text: desc,
                  recent_icon: icon,
                },
                idx
              ) => (
                <li
                  className="grid px-3 border border-light-gray rounded-ten h-75 items-center py-2"
                  key={idx}
                >
                  <div className="grid grid:auto-1fr-auto-auto items-center gap-5">
                    <div className="grid items-center justify-items-center rounded-full text-center px-2 py-2 leading-tight bg-accent h-53 w-53">
                      {icons[icon] && React.createElement(icons[icon], {stroke: theme?.colors?.primary})}
                    </div>
                    <div className="grid items-center">
                      <div
                        className="desc text-base font-semibold flex 1 leading-tight"
                        dangerouslySetInnerHTML={{ __html: desc }}
                      ></div>
                      <div className="text-xs font-normal">
                        {date}
                      </div>
                    </div>
                  </div>
                </li>
              )
            )}
        </ul>
        {!options?.length && <EmptyRecord />
        }
      </div>
    );
}

export default RecentTransactionList1;
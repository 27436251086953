import * as React from "react";

const UndeployedTenderIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="30"
    fill="none"
    viewBox="0 0 26 30"
  >
    <path
      fill="#D9241C"
      d="M18.778 15.714c-3.987 0-7.222 3.2-7.222 7.143C11.556 26.8 14.79 30 18.778 30 22.764 30 26 26.8 26 22.857c0-3.943-3.236-7.143-7.222-7.143zm2.383 10.5l-3.105-3.071V18.57H19.5v3.986l2.672 2.643-1.01 1.014zm-.939-23.357H15.63A4.345 4.345 0 0011.556 0a4.345 4.345 0 00-4.074 2.857H2.89C1.3 2.857 0 4.143 0 5.714v21.429C0 28.714 1.3 30 2.889 30h8.825a9.637 9.637 0 01-2.05-2.857H2.888V5.714h2.889V10h11.555V5.714h2.89v7.257c1.025.143 1.993.443 2.888.858V5.714c0-1.571-1.3-2.857-2.889-2.857zm-8.666 2.857a1.44 1.44 0 01-1.445-1.428c0-.786.65-1.429 1.445-1.429A1.44 1.44 0 0113 4.286a1.44 1.44 0 01-1.444 1.428z"
    ></path>
  </svg>
);

export default UndeployedTenderIcon;

import * as React from "react";

const EmptyTransactionIcon = (props) => (
  <svg width={91} height={99} viewBox="0 0 91 99" fill="none" {...props}>
    <path
      d="M81.9792 0.146973C79.7027 0.146973 77.5195 1.05129 75.9098 2.66097C74.3002 4.27066 73.3958 6.45387 73.3958 8.73031V98.8553H0.4375V8.73031C0.4375 6.45387 1.34181 4.27066 2.9515 2.66097C4.56119 1.05129 6.74439 0.146973 9.02083 0.146973H81.9792Z"
      fill="#E2E2E2"
    />
    <path
      d="M81.9792 0.145773C79.7027 0.145773 77.5195 1.05009 75.9098 2.65977C74.3002 4.26946 73.3958 6.45267 73.3958 8.72911V94.5624C55.7534 94.5624 38.8336 87.554 26.3585 75.0789C13.8834 62.6039 6.875 45.684 6.875 28.0416C6.875 1.92682 6.6175 3.1714 7.62605 0.918273C9.45 -0.19756 0.909586 0.145773 81.9792 0.145773Z"
      fill="#EEEEEE"
    />
    <path
      d="M90.5625 8.72925V17.3126H73.3958C73.3958 9.35154 72.6877 5.91821 75.9064 2.65654C77.1069 1.45478 78.6369 0.636209 80.3028 0.30446C81.9687 -0.027289 83.6956 0.142701 85.2648 0.79291C86.834 1.44312 88.175 2.54431 89.1181 3.95707C90.0612 5.36984 90.5638 7.03065 90.5625 8.72925Z"
      fill="#E2E2E2"
    />
    <path
      d="M90.5625 9.13688V13.0208H86.2708C84.8392 13.0179 83.431 12.6568 82.1746 11.9706C80.9181 11.2844 79.8532 10.2948 79.0769 9.09195C78.3006 7.88907 77.8375 6.51112 77.7297 5.08355C77.622 3.65597 77.873 2.22411 78.46 0.918341C79.7976 0.315181 81.2676 0.065017 82.7295 0.191738C84.1913 0.31846 85.5963 0.817834 86.8102 1.64218C88.0241 2.46652 89.0064 3.5883 89.6633 4.90039C90.3203 6.21248 90.6298 7.67108 90.5625 9.13688Z"
      fill="#EEEEEE"
    />
    <rect x={11} y={21} width={23} height={4} rx={2} fill="#AFAFAF" />
    <rect x={11} y={38} width={53} height={4} rx={2} fill="#AFAFAF" />
    <rect x={11} y={50} width={53} height={4} rx={2} fill="#AFAFAF" />
    <rect x={11} y={62} width={53} height={4} rx={2} fill="#AFAFAF" />
  </svg>
);

export default EmptyTransactionIcon;

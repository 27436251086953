import { useField, ErrorMessage, Field, useFormikContext, getIn } from "formik";
import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import apiEndpoints from "../apiEndpoints";
import appFetch from "../appFetch";
import "../assets/k-dropdown.css";
import uuid from "react-uuid";
import FileThumbnail from "./FileThumbnail";
import FilePreviewer, { FilePreviewerThumbnail } from "react-file-previewer";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const allowedTypes = [
  "image/jpeg",
  "image/gif",
  "image/png",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "text/csv",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "text/plain",
  "application/msword",
];

const getName = (str) => {
  return str?.split("/")?.pop();
};

export const TogglePreview = ({ url, removeResource }) => {
  const [previewFile, setPreviewFile] = useState(null);

  return (
    <>
      {previewFile && (
        <FilePreviewerThumbnail
          style={{
            maxWidth: "300px",
            maxHeight: "300px",
            overflow: "hidden",
            objectFit: "contain",
          }}
          hideControls
          file={{
            url: previewFile,
          }}
        />
      )}

      <li class="k-file k-file-success" key={getName(url)}>
        <div class="k-file-single">
          <FileThumbnail name={getName(url)} />
          <span class="k-file-name-size-wrapper">
            <span class="k-file-name" title="Group 4.png">
              {getName(url)}
            </span>
            <span
              className="k-file-size hover:text-red-600 hover:underline cursor-pointer"
              onClick={() => setPreviewFile(previewFile ? null : url)}
            >
              {" "}
              {previewFile ? "Close Preview" : "Preview"}
            </span>
          </span>
          <strong class="k-upload-status">
            <button
              type="button"
              onClick={() => removeResource(url)}
              tabIndex="-1"
              class="k-button k-button-icon k-flat k-upload-action"
            >
              <span
                aria-label="Remove"
                title="Remove"
                class="k-icon k-delete k-i-x"
              ></span>
            </button>
          </strong>
        </div>
      </li>
    </>
  );
};

export default function StyledDropzone({
  name,
  label,
  validation,
  showLabel,
  indent = "false",
}) {
  const [field, meta, { setValue, setTouched }] = useField({ name });
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState(null);
  const [uploadComplete, setUploadComplete] = useState(false);
  const { maxfiles } = validation ?? {};

  const isIndentYes = (indent || "").toString().toLowerCase() === "yes";

  // console.log("maxfiles",maxfiles)

  const { values } = useFormikContext();

  const fieldValue = getIn(values, name);
  console.log("fieldValue", fieldValue);

  useEffect(() => {
    if (fieldValue?.length == 0 && files?.length) {
      setFiles(null);
      setUploadComplete(false);
    }
  }, [fieldValue]);

  const thumbs = files?.map((file) => (
    <div style={thumb} key={file}>
      <div style={thumbInner}>
        <img src={file} style={img} />
      </div>
    </div>
  ));

  const {
    getRootProps,
    getInputProps,
    rootRef, // Ref to the `<div>`
    inputRef, // Ref to the `<input>`
    open,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: allowedTypes.join(","),
    maxFiles: maxfiles,
    onDrop: (acceptedFiles) => {
      console.log("accepted files", acceptedFiles);

      if (uploadComplete) {
        setUploadComplete(false);
      }

      let myFiles = acceptedFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
          uploaded: false,
          key: uuid(),
        });
      });
      setFiles((prev) => [...(prev ?? []), ...myFiles]);
    },
  });

  const removeFile = (removeKey) => {
    let newFileList = files?.filter(({ key }) => key !== removeKey);
    setFiles(newFileList);
  };

  const uploadFiles = () => {
    let formData = new FormData();
    files
      .filter(({ uploaded }) => !uploaded)
      .forEach((file) => {
        formData.append("formFileList", file);
      });

    setUploading(true);
    let token = localStorage.getItem("token") ?? "";
    appFetch
      .post(apiEndpoints.fileUpload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
      .then((result) => {
        //let uploadedFiles = [...files];
        // uploadedFiles.map(file => {
        //   file.uploaded = true;
        // })
        //setFiles(uploadedFiles);
        setFiles(null);
        let prev = fieldValue;
        console.log("prev", prev);
        console.log("result", result);
        const urls = result.map((r) => r.uri).filter((uri) => uri);
        setValue(Array.isArray(prev) ? prev.concat(urls) : urls);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setUploading(false);
        setUploadComplete(true);
      });
  };

  console.log("files", files);

  const getSize = (size) => {
    let sizeMb = (size / (1024 * 1024)).toFixed(1);

    console.log("sizeMb", sizeMb);

    if (parseFloat(sizeMb) <= 0) {
      return (size / 1024).toFixed(1) + " KB";
    }

    return sizeMb + " MB";
  };

  const removeResource = (url) => {
    let name = getName(url);

    let files = fieldValue?.filter((m) => getName(m) !== name);

    if (Array.isArray(files)) {
      setValue(files);
    }
  };

  return (
    <div>
      <div class="k-widget k-upload">
        <div class="k-dropzone">
          <div
            role="button"
            onClick={open}
            class="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-upload-button"
            aria-label="Select files..."
            tabindex="0"
          >
            {/* <input {...getInputProps()} autocomplete="off" name={name} type="file" tabindex="-1" multiple="" title="No files selected" /><span>Select files...</span> */}
            <input {...getInputProps()} />
            <span>Select files...</span>
          </div>
          {/* <div class="k-dropzone-hint">{label}</div> */}
          {files?.length > 0 && !uploadComplete && !uploading && (
            <button
              onClick={uploadFiles}
              class="k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base k-rounded-md buttons-container-button"
            >
              <span
                role="presentation"
                class="k-button-icon k-icon k-i-upload"
              ></span>
              <span class="k-button-text">Upload</span>
            </button>
          )}
          {uploadComplete && (
            <div class="k-upload-status k-upload-status-total">
              <span class="k-i-checkmark k-icon"></span>Done
            </div>
          )}
          {uploading && (
            <div class="k-upload-status k-upload-status-total">
              <span class="k-i-loading k-icon"></span>Uploading...
            </div>
          )}
        </div>

        {showLabel && (files?.length ?? 0) + (fieldValue?.length ?? 0) == 0 && (
          <div
            class="k-external-dropzone"
            {...getRootProps({ isFocused, isDragAccept, isDragReject })}
            tabindex="0"
          >
            <div
              class="k-dropzone-inner"
              style={{
                background: "#FAFAFA",
                border: "1.2px dashed rgba(169, 67, 143, 0.2)",
                boxSizing: "border-box",
              }}
            >
              <span class="k-dropzone-hint">
                <span>{label}</span>
              </span>
              <span class="k-dropzone-note">
                <span></span>
              </span>
            </div>
          </div>
        )}

        <ul class="k-upload-files k-reset">
          {files?.map(({ key, name, uploaded, size }) => (
            <li class="k-file k-file-success" key={key}>
              <div class="k-file-single">
                <FileThumbnail name={name} />
                {/* <span class="k-file-group-wrapper">
                          <span class="k-file-group k-icon k-i-file-image"></span>
                          <span class="k-file-state"></span>
                      </span> */}
                <span class="k-file-name-size-wrapper">
                  <span class="k-file-name" title="Group 4.png">
                    {name}
                  </span>
                  {!uploaded && (
                    <span class="k-file-size">{getSize(size)}</span>
                  )}
                  {uploaded && (
                    <span class="k-file-validation-message k-text-success">
                      File(s) successfully uploaded.
                    </span>
                  )}
                </span>
                {!uploaded && (
                  <strong class="k-upload-status">
                    <button
                      type="button"
                      onClick={() => removeFile(key)}
                      tabIndex="-1"
                      class="k-button k-button-icon k-flat k-upload-action"
                    >
                      <span
                        aria-label="Remove"
                        title="Remove"
                        class="k-icon k-delete k-i-x"
                      ></span>
                    </button>
                  </strong>
                )}
              </div>
            </li>
          ))}
        </ul>

        <ul class="k-upload-files k-reset">
          {fieldValue?.map((str, idx) => (
            <>
              <TogglePreview url={str} removeResource={removeResource} />
              {/* <div class="k-file-single">
                  <FileThumbnail name={getName(str)} />
                      <span class="k-file-name-size-wrapper">
                      <span class="k-file-name" title="Group 4.png">{getName(str)}</span>
                          <TogglePreview url={str} />
                        </span>
                      <strong class="k-upload-status">
                        <button type="button" onClick={() => removeResource(str)} tabIndex="-1" class="k-button k-button-icon k-flat k-upload-action">
                        <span aria-label="Remove" title="Remove" class="k-icon k-delete k-i-x"></span>
                        </button>
                      </strong> 
              </div> */}
            </>
          ))}

          {/* <li class="k-file k-file-success" data-uid="4d706bb2-c156-4f14-8bd0-7de2d04c02e1" tabIndex="-1">
              <div class="k-file-single">
                  <div class="k-progressbar" style={{transition: "opacity 1s ease-in-out 0s", opacity: 0}}><span class="k-progress" style={{width: "100%"}}></span></div><span class="k-file-group-wrapper"><span class="k-file-group k-icon k-i-file-image"></span><span class="k-file-state"></span></span><span class="k-file-name-size-wrapper"><span class="k-file-name" title="Group 6.jpg">Group 6.jpg</span><span class="k-file-validation-message k-text-success">File(s) successfully uploaded.</span></span><strong class="k-upload-status"><button type="button" tabIndex="-1" class="k-button k-button-icon k-flat k-upload-action"><span aria-label="Remove" title="Remove" class="k-icon k-delete k-i-x"></span></button></strong>
              </div>
          </li>
          <li class="k-file k-file-success" data-uid="bfbeb0ba-e45d-4873-b23d-57da6724d285" tabIndex="-1">
              <div class="k-file-single">
                  <div class="k-progressbar" style={{transition: "opacity 1s ease-in-out 0s", opacity: 0}}><span class="k-progress" style={{width: "100%"}}></span></div><span class="k-file-group-wrapper"><span class="k-file-group k-icon k-i-file-image"></span><span class="k-file-state"></span></span><span class="k-file-name-size-wrapper"><span class="k-file-name" title="Group 6.png">Group 6.png</span><span class="k-file-validation-message k-text-success">File(s) successfully uploaded.</span></span><strong class="k-upload-status"><button type="button" tabIndex="-1" class="k-button k-button-icon k-flat k-upload-action"><span aria-label="Remove" title="Remove" class="k-icon k-delete k-i-x"></span></button></strong>
              </div>
          </li> */}
        </ul>
      </div>
      <ErrorMessage
        name={name}
        render={(msg) => (
          <div className="text-xs text-red-600 mt-1">
            {files?.length > 0 ? "please upload selected files" : msg}
          </div>
        )}
      />
    </div>
  );

  {
    /* return (
      <div>
        <Container {...getRootProps({isFocused, isDragAccept, isDragReject})}>
          <input {...getInputProps()} name={name} />
           {uploading && <Spinner fill="#cccccc"  />}
           {!uploading && thumbs.length == 0 && <p>{label}</p>}
            {thumbs.length > 0 && <aside style={thumbsContainer}>
              {thumbs}
            </aside>
            }
        </Container>
        <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
      </div>
    ); */
  }
}

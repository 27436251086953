import * as React from "react";

const GroupIcon = (props) => (
  <svg
    width={32}
    height={30}
    viewBox="0 0 32 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1743 6.00622C10.6972 6.28037 9.28492 6.81321 8.01912 7.58219C6.09736 8.74968 4.59953 10.4091 3.71504 12.3505C2.83055 14.292 2.59913 16.4283 3.05004 18.4894C3.50095 20.5504 4.61394 22.4436 6.24826 23.9296C7.88258 25.4155 9.96484 26.4274 12.2317 26.8374C14.4986 27.2474 16.8483 27.037 18.9836 26.2328C21.1189 25.4286 22.9441 24.0668 24.2281 22.3195C25.0739 21.1686 25.66 19.8846 25.9615 18.5416H21.1352C20.9413 19.0411 20.6789 19.5194 20.3517 19.9645C19.5799 21.0147 18.4829 21.8333 17.1995 22.3166C15.916 22.8 14.5037 22.9264 13.1412 22.68C11.7787 22.4336 10.5272 21.8254 9.54488 20.9323C8.56256 20.0391 7.8936 18.9012 7.62258 17.6624C7.35156 16.4236 7.49065 15.1396 8.02228 13.9727C8.5539 12.8057 9.45418 11.8084 10.6093 11.1066C11.0988 10.8092 11.6249 10.5706 12.1743 10.3943V6.00622ZM12.9566 3.74517C13.8118 3.65842 14.5115 4.30082 14.5115 5.08322V10.7387C14.5115 11.5211 13.7953 12.1334 12.9819 12.3887C12.6045 12.5072 12.2433 12.6697 11.9077 12.8735C11.137 13.3417 10.5363 14.0072 10.1816 14.7859C9.82685 15.5645 9.73404 16.4213 9.91488 17.2479C10.0957 18.0745 10.5421 18.8337 11.1975 19.4297C11.853 20.0256 12.6881 20.4314 13.5972 20.5959C14.5063 20.7603 15.4487 20.6759 16.3051 20.3534C17.1615 20.0309 17.8934 19.4847 18.4084 18.7839C18.6326 18.4789 18.8113 18.1504 18.9416 17.8073C19.2225 17.0677 19.8959 16.4166 20.7564 16.4166H26.9767C27.8372 16.4166 28.5437 17.0528 28.4483 17.8303C28.2003 19.8519 27.4227 21.7974 26.1715 23.5001C24.6306 25.5968 22.4404 27.231 19.878 28.196C17.3156 29.161 14.496 29.4135 11.7757 28.9216C9.0555 28.4296 6.55679 27.2153 4.5956 25.4322C2.63442 23.6491 1.29883 21.3772 0.75774 18.904C0.21665 16.4307 0.494357 13.8671 1.55574 11.5373C2.61713 9.20759 4.41453 7.21631 6.72064 5.81532C8.59332 4.67765 10.7332 3.97072 12.9566 3.74517Z"
      fill="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.0777 11.4583C28.9289 10.7954 28.7104 10.1454 28.4243 9.51729C27.837 8.2282 26.9762 7.05691 25.8911 6.07029C24.8059 5.08367 23.5177 4.30104 22.0999 3.76708C21.4091 3.50692 20.6941 3.30828 19.965 3.17297V7.56103C20.083 7.59887 20.1999 7.63962 20.3157 7.68324C21.1679 8.00417 21.9422 8.47457 22.5945 9.06759C23.2467 9.6606 23.7641 10.3646 24.1171 11.1394C24.165 11.2447 24.2099 11.3511 24.2515 11.4583H29.0777ZM31.5646 12.1695C31.66 12.9471 30.9535 13.5833 30.0929 13.5833H23.8727C23.0121 13.5833 22.3387 12.9321 22.0579 12.1926C22.0272 12.1118 21.9938 12.0318 21.9578 11.9526C21.7222 11.4356 21.377 10.9659 20.9418 10.5702C20.5066 10.1745 19.99 9.86062 19.4213 9.64648C19.3343 9.61369 19.2463 9.58334 19.1574 9.55545C18.344 9.3001 17.6278 8.68785 17.6278 7.90545V2.24997C17.6278 1.46756 18.3275 0.825167 19.1828 0.911923C20.4905 1.04458 21.7738 1.3442 22.9943 1.80384C24.6957 2.44458 26.2416 3.38374 27.5437 4.56769C28.8459 5.75164 29.8789 7.15718 30.5836 8.70409C31.0891 9.81374 31.4187 10.9805 31.5646 12.1695Z"
      fill="inherit"
    />
  </svg>
);

export default GroupIcon;

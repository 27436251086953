import * as React from "react";

const CategoryFillIcon = (props) => (
  <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
    <path
      d="M6.22222 0H0.888889C0.653141 0 0.427048 0.0936505 0.260349 0.260349C0.0936505 0.427048 0 0.653141 0 0.888889V6.22222C0 6.45797 0.0936505 6.68406 0.260349 6.85076C0.427048 7.01746 0.653141 7.11111 0.888889 7.11111H6.22222C6.45797 7.11111 6.68406 7.01746 6.85076 6.85076C7.01746 6.68406 7.11111 6.45797 7.11111 6.22222V0.888889C7.11111 0.653141 7.01746 0.427048 6.85076 0.260349C6.68406 0.0936505 6.45797 0 6.22222 0ZM15.1111 8.88889H9.77778C9.54203 8.88889 9.31594 8.98254 9.14924 9.14924C8.98254 9.31594 8.88889 9.54203 8.88889 9.77778V15.1111C8.88889 15.3469 8.98254 15.573 9.14924 15.7397C9.31594 15.9064 9.54203 16 9.77778 16H15.1111C15.3469 16 15.573 15.9064 15.7397 15.7397C15.9064 15.573 16 15.3469 16 15.1111V9.77778C16 9.54203 15.9064 9.31594 15.7397 9.14924C15.573 8.98254 15.3469 8.88889 15.1111 8.88889ZM12.4444 0C10.4836 0 8.88889 1.59467 8.88889 3.55556C8.88889 5.51644 10.4836 7.11111 12.4444 7.11111C14.4053 7.11111 16 5.51644 16 3.55556C16 1.59467 14.4053 0 12.4444 0ZM3.55556 8.88889C1.59467 8.88889 0 10.4836 0 12.4444C0 14.4053 1.59467 16 3.55556 16C5.51644 16 7.11111 14.4053 7.11111 12.4444C7.11111 10.4836 5.51644 8.88889 3.55556 8.88889Z"
      fill="inherit"
    />
  </svg>
);

export default CategoryFillIcon;

import * as React from "react";

const PendingIcon = (props) => (
  <svg
    fill={props?.fill}
    width="24"
    height="24"
    viewBox="0 0 32 32"
    id="icon"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle fill="inherit" cx="9" cy="16" r="2" />
    <circle fill="inherit" cx="23" cy="16" r="2" />
    <circle fill="inherit" cx="16" cy="16" r="2" />
    <path
      fill="inherit"
      d="M16,30A14,14,0,1,1,30,16,14.0158,14.0158,0,0,1,16,30ZM16,4A12,12,0,1,0,28,16,12.0137,12.0137,0,0,0,16,4Z"
      transform="translate(0 0)"
    />
    <rect
      fill="none"
      id="_Transparent_Rectangle_"
      data-name="&lt;Transparent Rectangle&gt;"
      class="cls-1"
      width="32"
      height="32"
    />
  </svg>
);

export default PendingIcon;

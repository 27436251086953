import * as React from "react";

const SetupIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    {...props}
  >
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M5 1.536H3a1.5 1.5 0 00-1.5 1.5v2a1.5 1.5 0 001.5 1.5h2a1.5 1.5 0 001.5-1.5v-2a1.5 1.5 0 00-1.5-1.5zm-2-1.5a3 3 0 00-3 3v2a3 3 0 003 3h2a3 3 0 003-3v-2a3 3 0 00-3-3H3zM5 11.536H3a1.5 1.5 0 00-1.5 1.5v2a1.5 1.5 0 001.5 1.5h2a1.5 1.5 0 001.5-1.5v-2a1.5 1.5 0 00-1.5-1.5zm-2-1.5a3 3 0 00-3 3v2a3 3 0 003 3h2a3 3 0 003-3v-2a3 3 0 00-3-3H3zM15 1.536h-2a1.5 1.5 0 00-1.5 1.5v2a1.5 1.5 0 001.5 1.5h2a1.5 1.5 0 001.5-1.5v-2a1.5 1.5 0 00-1.5-1.5zm-2-1.5a3 3 0 00-3 3v2a3 3 0 003 3h2a3 3 0 003-3v-2a3 3 0 00-3-3h-2zM15 11.5h-2a1.5 1.5 0 00-1.5 1.5v2a1.5 1.5 0 001.5 1.5h2a1.5 1.5 0 001.5-1.5v-2a1.5 1.5 0 00-1.5-1.5zM13 10a3 3 0 00-3 3v2a3 3 0 003 3h2a3 3 0 003-3v-2a3 3 0 00-3-3h-2z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default SetupIcon;

import * as React from "react";

const TransactionIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="17"
    fill="none"
    viewBox="0 0 20 17"
    {...props}
  >
    <path
      fill="inherit"
      d="M4 10.286a.75.75 0 000 1.5h6a.75.75 0 000-1.5H4z"
    ></path>
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M3 .036a3 3 0 00-3 3v10a3 3 0 003 3h14a3 3 0 003-3v-10a3 3 0 00-3-3H3zm14 1.5H3a1.5 1.5 0 00-1.5 1.5v1.25h17v-1.25a1.5 1.5 0 00-1.5-1.5zm-15.5 11.5v-7.25h17v7.25a1.5 1.5 0 01-1.5 1.5H3a1.5 1.5 0 01-1.5-1.5z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default TransactionIcon;
